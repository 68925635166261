// src/utils/keyConverter.js
import _ from 'lodash';

/**
 * 递归地将对象的所有键从 camelCase 转换为 snake_case 使用 lodash
 * @param {Object|Array} obj - 要转换的对象或数组
 * @returns {Object|Array} 转换后的对象或数组
 */
export function convertKeysToSnakeWithLodash(obj) {
    if (Array.isArray(obj)) {
        return obj.map(convertKeysToSnakeWithLodash);
    } else if (obj !== null && typeof obj === 'object') {
        return Object.keys(obj).reduce((acc, key) => {
            const newKey = _.snakeCase(key);
            acc[newKey] = convertKeysToSnakeWithLodash(obj[key]);
            return acc;
        }, {});
    }
    return obj;
}

/**
 * 将 JSON 对象中的所有键从 camelCase 转换为 snake_case 使用 lodash
 * @param {Object} obj - 原始 JSON 对象
 * @returns {Object} 转换后的 JSON 对象
 */
export function convertJsonKeysToSnakeWithLodash(obj) {
    return convertKeysToSnakeWithLodash(obj);
}

export function convertToTitleCase(str) {
  return str
    .split("_")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}
